<template>
  <div class="UiPickerLocation">
    <UiPickerCountry v-model="location.country" />

    <UiPickerState
      v-model="location.state"
      :country="location.country"
      v-if="location.country"
    />

    <UiPickerCity
      v-model="location.city"
      :state="location.state"
      v-if="location.country && location.state"
    />
  </div>
</template>

<script>
import UiPickerCountry from "../UiPickerCountry/UiPickerCountry.vue";
import UiPickerState from "../UiPickerState/UiPickerState.vue";
import UiPickerCity from "../UiPickerCity/UiPickerCity.vue";

import useApi from "@/modules/api/mixins/useApi";
import apiLocation from "./api";

export default {
  name: "ui-picker-location",
  mixins: [useApi],
  api: apiLocation,

  components: {
    UiPickerCountry,
    UiPickerState,
    UiPickerCity,
  },

  props: {
    value: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      location: {},
    };
  },

  methods: {
    async emitInput() {
      let output = JSON.parse(JSON.stringify(this.location));
      output.text = await this.toText(output);
      this.$emit("input", output);
    },

    async toText(location) {
      return await this.$api.abstract(location);
    },
  },

  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.location = JSON.parse(JSON.stringify(value));
        // this.location.lang = this.$store.state.i18n.language;
      },
    },

    "location.country"(value) {
      this.location.state = null;
      this.location.city = null;
      this.emitInput();
    },

    "location.state"(value) {
      this.location.city = null;
      this.emitInput();
    },

    "location.city"(value) {
      this.emitInput();
    },
  },
};
</script>
