<template>
  <phi-page class="state-sebas">
    <div slot="toolbar">
      <mu-icon-button
        @click="$router.go(-1)"
        icon="arrow_back"
      ></mu-icon-button>
      <h1>Sebastian dev</h1>
    </div>

    <div>
      <pre>{{ location }}</pre>
      <UiPickerLocation v-model="location" />
      <hr>
      
      <pre>{{ location2 }}</pre>
      <UiPickerLocation v-model="location2" />
    </div>
  </phi-page>
</template>

<script>
import UiPickerLocation from "@/modules/ui/components/UiPickerLocation/UiPickerLocation.vue";

export default {
  components: {
    UiPickerLocation,
  },

  data() {
    return {
      location: {
        country: "CO",
        state: null,
        city: null,
        text: null,
      },
      location2: {
        country: "DE",
        state: null,
        city: null,
        text: null,
      },
    };
  },
};
</script>

<style lang="scss">
.state-sebas {
}
</style>
