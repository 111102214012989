<template>
  <div class="UiPicker">
    <!-- Current -->
    <div class="UiPicker__current" @click="toggleOpen()">
      <p v-if="currentOption">{{ currentOption[optionText] }}</p>
      <p v-else>{{ defaultLabel }}</p>
      <UiIcon :value="isOpen ? 'mdi:menu-up' : 'mdi:menu-down'" />
    </div>

    <!-- Wrapper selected -->
    <div class="UiPicker__wrapper" v-show="isOpen">
      <div class="UiPicker__wrapper_search"> <!-- Wrapper search -->
        <input type="search" :placeholder="$t('UiPicker.label.search')" v-model="searchOption" />
        <UiIcon value="mdi:magnify" />
      </div>

      <!-- Options -->
      <template v-if="filterOptions && filterOptions.length > 0">
        <div class="UiPicker__wrapper_options">
          <div
            v-for="(option, index) in filterOptions"
            :key="index"
            @click="selectedOption(option)"
            :class="['UiPicker__wrapper_options_item', option[optionKey] == value ? 'UiPicker__wrapper_options_item--selected' : null]"
          >
            {{ option[optionText] }}
          </div>
        </div>
      </template>

      <div v-else class="UiPicker__wrapper--noOptions">{{ $t('UiPicker.label.noResults') }}</div>
    </div>
  </div>
</template>

<script>
import useI18n from '@/modules/i18n/mixins/useI18n.js';
import { UiIcon } from "../index.js";

export default {
  mixins: [useI18n],

  components: {
    UiIcon,
  },

  props: {
    value: {
      type: [String, Number],
      default: null,
    },

    options: {
      type: Array,
      default: () => [],
    },

    optionText: {
      type: String,
      required: true,
    },

    optionKey: {
      type: String,
      required: true,
    },

    label: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      isOpen: false,
      searchOption: "",
    };
  },

  computed: {
    currentOption() {
      if (!this.value) {
        return;
      }

      let result = this.options.filter((option) => option[this.optionKey] == this.value);

      return result[0];
    },

    filterOptions() {
      if (!this.searchOption) {
        return this.options;
      }

      let normalizedSearch = this.toNormalize(this.searchOption);

      return this.options.filter((option) => {
        if (option[this.optionText]) {
          if (this.toNormalize(option[this.optionText]).includes(normalizedSearch)) {
            return option;
          }
        }
      });
    },

    defaultLabel() {
      if (!this.label) {
        return this.$t('UiPicker.label.selectValue');
      }

      return this.label;
    }
  },

  methods: {
    toNormalize(string) {
      return string
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "") // normalize('NFD').replace(/[\u0300-\u036f]/g, '') normaliza acentos y dieresis
        .toLowerCase()
        .replace(/[^a-z0-9]/g, "")
        .trim();
    },

    selectedOption(option) {
      this.isOpen = false;
      this.$emit("input", option[this.optionKey]);
    },

    toggleOpen() {
      this.isOpen = !this.isOpen;
    },

    outsideClickListener(event) {
      if (!this.$el.contains(event.target)) {
        this.isOpen = false;
      }
    },
  },

  watch: {
    isOpen(value) {
      this.searchOption = "";

      if (value) {
        window.addEventListener("click", this.outsideClickListener, true);
      } else {
        window.removeEventListener("click", this.outsideClickListener);
      }
    },
  },

  i18n: {
    en: {
      "UiPicker.label.selectValue": "Select a value",
      "UiPicker.label.noResults": "No results",
      "UiPicker.label.search": "Search",
    },

    es: {
      "UiPicker.label.selectValue": "Seleccionar un valor",
      "UiPicker.label.noResults": "Sin resultados",
      "UiPicker.label.search": "Buscar",
    },

    de: {
      "UiPicker.label.selectValue": "Wähle einen Wert",
      "UiPicker.label.noResults": "Keine Ergebnisse",
      "UiPicker.label.search": "Suche",
    },
  }
};
</script>

<style lang="scss">
.UiPicker {
  width: 250px;
  position: relative;

  &__current {
    z-index: 1;
    padding: 8px;
    display: flex;
    background: white;
    flex-wrap: nowrap;
    border-radius: 8px;
    align-items: center;
    border: 1px solid #dedede;
    justify-content: space-between;

    & > p {
      margin: 0;
    }
  }

  &__wrapper {
    left: 0;
    top: 100%;
    z-index: 1;
    width: 100%;
    background: white;
    position: absolute;
    border-radius: 4px;
    border: 1px solid #dedede;
    border-top: none;

    &_search {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      border-bottom: 1px solid #dedede;

      & > input {
        width: 100%;
        border: none;
        padding: 15px 8px;
      }
    }

    &_options {
      width: 100%;
      overflow: auto;
      max-height: 150px;

      &_item {
        padding: 8px;
        cursor: pointer;

        &:hover {
          background: #f2f2f2;
        }

        &--selected {
          background: pink;

          &:hover {
            background: pink;
          }
        }
      }
    }

    &--noOptions {
      padding: 8px;
      text-align: center;
    }
  }
}
</style>
