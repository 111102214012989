<template>
  <div class="UiPickerCity">
    <label v-if="!hiddenLabel">{{ $t('UiPickerCity.label.city') }}</label>

    <UiPicker
      :label="$t('UiPickerCity.label')"
      :options="cities"
      optionText="name"
      optionKey="iso2"
      :value="value"
      @input="$emit('input', $event)"
    />
  </div>
</template>

<script>
import useI18n from '@/modules/i18n/mixins/useI18n.js';
import UiPicker from "../UiPicker/UiPicker.vue";
import useApi from "@/modules/api/mixins/useApi";
import apiLocation from "../UiPickerLocation/api";

export default {
  name: "ui-picker-city",
  mixins: [useApi, useI18n],
  api: apiLocation,

  components: {
    UiPicker,
  },

  props: {
    value: {
      type: String,
      default: null,
    },

    state: {
      type: [String, Number],
      default: null,
    },

    country: {
      type: [String, Number],
      default: null,
    },

    hiddenLabel: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      cities: [],
    };
  },

  methods: {
    async getCities(stateIso, countryIso) {
      this.cities = await this.$api.getCities(stateIso, countryIso);
    },
  },

  watch: {
    state: {
      immediate: true,
      handler(iso) {
        this.getCities(iso, this.country);
      },
    },

    country: {
      immediate: true,
      handler(iso) {
        this.getCities(this.state, iso);
      },
    },
  },

  i18n: {
    en: {
      "UiPickerCity.label": "Select city",
      "UiPickerCity.label.city": "City",
    },

    es: {
      "UiPickerCity.label": "Seleccionar ciudad",
      "UiPickerCity.label.city": "Ciudad",
    },

    de: {
      "UiPickerCity.label": "Stadt wählen",
      "UiPickerCity.label.city": "Stadt",
    },
  }
};
</script>
